<template>
  <div class="videoMonitorEdit__wrapper">
    <div class="breadcrumb"></div>
    <div class="content">
      <h2 class="title">
        <div class="title_icon"></div>
        {{ isEdit ? "编辑监控设备" : "添加监控设备" }}
      </h2>
      <div class="wrapper" @keydown.enter.prevent="submitData">
        <el-form
          label-position="right"
          label-width="120px"
          :model="formInline"
          style="width: 300px; float: left"
          :rules="rules"
          :ref="'form'"
        >
          <el-form-item
            :label="$t('searchModule.Equipment_name')"
            prop="monitorEquipmentChannelName"
          >
            <el-input
              :maxlength="50"
              v-model.trim="formInline.monitorEquipmentChannelName"
              placeholder="请输入设备名称"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Belonging_NVR')" prop="monitorEquipmentId">
            <el-select
              v-model="formInline.monitorEquipmentId"
              filterable
              placeholder="请选择所属NVR"
              @change="NVRChange"
            >
              <el-option
                :label="value.monitorEquipmentName"
                :value="value.monitorEquipmentId"
                :key="value.monitorEquipmentId"
                v-for="value in NVRList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Belonging_parking_lot')" prop="" v-if="parkName">
            <span>{{ parkName }}</span>
          </el-form-item>
          <el-form-item label="NVR通道" prop="monitorEquipmentChannelCode">
            <el-input
              :maxlength="50"
              v-model.trim="formInline.monitorEquipmentChannelCode"
              placeholder="请输入NVR通道号"
            ></el-input>
          </el-form-item>
          <el-form-item label="IP对讲设备编码" v-show="false">
            <el-input
              :maxlength="50"
              v-model.trim="formInline.mbEquipmentCode"
              placeholder="IP对讲设备编码"
            ></el-input>
          </el-form-item>
          <!-- 待联调 -->
          <el-form-item style="width: 800px" label="车场通道">
            <el-transfer
              target-order="push"
              filterable
              :filter-method="filterMethod"
              :titles="['未选择', '已选择']"
              filter-placeholder="请输入通道"
              v-model="berthIds"
              @change="berthChange"
              :props="{
                key: 'parkChannelId',
                label: 'parkChannelName',
              }"
              :data="berthIdList"
            >
            </el-transfer>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: left; margin-left: 10%">
        <el-button type="primary" @click="submitData" style="width: 88px">{{
          $t("button.preservation")
        }}</el-button>
        <el-button type="" @click="$router.go(-1)" style="width: 88px">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    let berthIdValidator = (rule, value, callback) => {
      if (this.berthIds.length == 0) {
        callback(new Error("请选择通道"));
      } else {
        callback();
      }
    };
    return {
      parkName: "",
      filterMethod(query, item) {
        return item.parkChannelName.indexOf(query) > -1;
      },
      isEdit: false,
      berthIds: [],
      berthIdList: [],
      berthCodes: [],
      monitorEquipmentIdList: [],
      formInline: {
        monitorEquipmentChannelName: "",
        monitorEquipmentChannelCode: "",
        monitorEquipmentId: "",
        mbEquipmentCode: "",
      },
      NVRList: [],
      rules: {
        monitorEquipmentChannelName: [
          {
            required: true,
            message: "请输入设备名称",
            trigger: ["blur"],
          },
        ],
        // berthId: [{ required: true, validator: berthIdValidator, trigger: "change" }],
        monitorEquipmentId: [
          {
            required: true,
            message: "请选择所属NVR",
            trigger: ["change"],
          },
        ],
        monitorEquipmentChannelCode: [
          {
            required: true,
            message: "请输入NVR通道号",
            trigger: ["blur"],
          },
        ],
      },
    };
  },
  created() {
    this.getNVRList();
    // this.getBerthIdList();
  },
  methods: {
    NVRChange(monitorEquipmentId, isDetail, berthIds, berthCodes) {
      const result = this.NVRList.find((item) => {
        this.parkName = item.parkName;
        return item.monitorEquipmentId == monitorEquipmentId;
      });
      // console.log(result);
      this.berthIds = [];
      this.getBerthIdList(result.parkId, isDetail, berthIds, berthCodes);
    },
    getNVRList() {
      this.$axios
        .get("/acb/2.0/bacb/monitorEquipment/listMonitorEquipment", {
          data: {
            page: 0,
            pageSize: 0,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.NVRList = res.value.list;
            // this.berthIdList = res.value;
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
          if (JSON.stringify(this.$route.query) == "{}") {
            this.isEdit = false;
          } else {
            this.isEdit = true;
            this.getDetail();
          }
        });
    },
    getDetail() {
      const monitorEquipmentChannelId = this.$route.query.monitorEquipmentChannelId;
      this.$axios
        .get(`/acb/2.0/bacb/monitorEquipmentChannel/queryById/${monitorEquipmentChannelId}`)
        .then((res) => {
          if (res.state == 0) {
            // this.berthIdList = res.value;
            this.formInline.monitorEquipmentChannelName = res.value.monitorEquipmentChannelName;
            this.formInline.monitorEquipmentChannelCode = res.value.monitorEquipmentChannelCode;
            this.formInline.mbEquipmentCode = res.value.mbEquipmentCode;
            this.formInline.monitorEquipmentId = res.value.monitorEquipmentId;
            // this.berthIds = res.value.berthIds.split(",");
            // this.berthCodes = res.value.berthCodes.split(",");
            this.NVRChange(
              res.value.monitorEquipmentId,
              true,
              res.value.parkChannelIds ? res.value.parkChannelIds.split(",") : [],
              res.value.parkChannelCodes ? res.value.parkChannelCodes.split(",") : []
            );
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    getBerthIdList(parkId, isDetail, berthIds, berthCodes) {
      this.$axios
        .get("/acb/2.0/bacb/parkChannel/parkChannelList", {
          data: {
            parkId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (this.isEdit && isDetail) {
              const result = [];
              berthCodes.forEach((item, indx) => {
                result.push({
                  parkChannelCode: item,
                  parkChannelId: berthIds[indx],
                });
              });
              this.berthIdList = res.value;
              this.berthIds = berthIds;
              // console.log('+++++++++')
              // console.log(this.berthIdList)
            } else {
              this.berthIdList = res.value;
            }

            // console.log(this.berthIdList);
            // console.log(this.berthIds)
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    berthChange() {},
    submitData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let data = {
            monitorEquipmentChannelName: this.formInline.monitorEquipmentChannelName,
            monitorEquipmentId: this.formInline.monitorEquipmentId,
            monitorEquipmentChannelCode: this.formInline.monitorEquipmentChannelCode,
            mbEquipmentCode: 0, // this.formInline.mbEquipmentCode,
            parkChannelList: this.berthIds.join(","),
          };
          if (this.isEdit) {
            const monitorEquipmentChannelId = this.$route.query.monitorEquipmentChannelId;
            data.monitorEquipmentChannelId = monitorEquipmentChannelId;
            this.$axios
              .post("/acb/2.0/bacb/monitorEquipmentChannel/update", {
                data,
              })
              .then((res) => {
                if (res.state == 0) {
                  this.$alert("操作成功！", this.$t("pop_up.Tips"), {
                    confirmButtonText: this.$t("pop_up.Determine"),
                  }).then(() => {
                    this.$router.go(-1);
                  });
                } else {
                  this.$alert(res.desc, this.$t("pop_up.Tips"), {
                    confirmButtonText: this.$t("pop_up.Determine"),
                  });
                }
              });
          } else {
            this.$axios
              .post("/acb/2.0/bacb/monitorEquipmentChannel/add", {
                data,
              })
              .then((res) => {
                if (res.state == 0) {
                  this.$alert("操作成功！", this.$t("pop_up.Tips"), {
                    confirmButtonText: this.$t("pop_up.Determine"),
                  }).then(() => {
                    this.$router.go(-1);
                  });
                } else {
                  this.$alert(res.desc, this.$t("pop_up.Tips"), {
                    confirmButtonText: this.$t("pop_up.Determine"),
                  });
                }
              });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.videoMonitorEdit__wrapper {
  .breadcrumb {
    height: 35px;
  }

  .content {
    background: #FFFFFF;
    overflow: hidden;
    border: 1px solid #C0CCDA;
    border-radius: 4px;
    padding: 20px;

    .wrapper {
      overflow: hidden;
    }
  }

}
.elementui-new .el-transfer-panel .el-input__prefix{
    left: 5px !important;
  }
</style>
